// layouts
import { Router, Routes, Route, Link, Navigate } from 'react-router-dom';
//
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import { auth } from './firebase';
import { AuthProvider } from './auth/AuthProvider';
import AddHospital from './pages/AddHospital';
import AddDoctor from './pages/AddDoctor';
import ChangePassword from './pages/ChangePassword';
import AddOffers from './pages/AddOffers';
import Offers from './pages/Offers';

// ----------------------------------------------------------------------

function RequireAuth({ children, redirectTo }) {
  const isAuthenticated = auth.currentUser;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

export default function CustomRouter() {
  console.log(auth.currentUser);
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route
            // children={[
            //   { path: 'app', element: <DashboardApp /> },
            //   { path: 'user', element: <User /> },
            //   { path: 'products', element: <Products /> },
            //   { path: 'blog', element: <Blog /> },
            // ]}
            path="/"
            element={
              <RequireAuth redirectTo="/login">
                <DashboardLayout />
              </RequireAuth>
            }
          >
            <Route path="/dashboard/app" element={<DashboardApp />} />
            <Route path="/dashboard/manage/centre" element={<AddHospital isEditing />} />
            <Route path="/dashboard/add-doctor" element={<AddDoctor />} />
            <Route path="/dashboard/add-offers" element={<AddOffers />} />
            <Route path="/dashboard/offers" element={<Offers />} />
          </Route>
          <Route exact path="/change-password" element={<ChangePassword />} />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </AuthProvider>
    </>
  );
}
