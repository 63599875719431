// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  {
    title: 'Manage Centre',
    path: '/dashboard/manage/centre',
    icon: getIcon('eva:plus-square-outline'),
  },
  // {
  //   title: 'Add Doctors',
  //   path: '/dashboard/add-doctor',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  {
    title: 'Add Offers',
    path: '/dashboard/add-offers',
    icon: getIcon('bxs:offer'),
  },
  {
    title: 'Offers',
    path: '/dashboard/offers',
    icon: getIcon('ic:round-list'),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },

  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
