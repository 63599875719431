import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { Button, Stack, TextField, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { uploadMediaToStorage, deleteMediaFromStorage, uploadOffer } from 'src/helpers/helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

export default function AddOfferForm() {
  const currentDate = new Date().toISOString();
  const [submitting, setSubmitting] = useState(false);
  const [selectedOfferImage, setSelectedOfferImage] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: '',
      startTime: currentDate,
      endTime: currentDate,
      offerImageUrl: '',
    },
    onSubmit: async (values) => {
      if (dayjs(values.startTime).isAfter(values.endTime)) {
        enqueueSnackbar('Offer Start time cannot be after End time. Please double check the offer dates.', {
          variant: 'error',
        });
        return;
      }
      if (dayjs(values.startTime).isSame(values.endTime)) {
        enqueueSnackbar('Offer Start time is same as End time. Please double check the offer dates.', {
          variant: 'error',
        });
        return;
      }
      if (!values.title) {
        enqueueSnackbar('Please enter offer title.', {
          variant: 'error',
        });
        return;
      }
      if (!values.offerImageUrl) {
        enqueueSnackbar('Please choose offer image.', {
          variant: 'error',
        });
        return;
      }
      setSubmitting(true);
      const response = await uploadOffer(values);
      setSubmitting(false);
      if (response.error) {
        enqueueSnackbar(response.message, { variant: 'error' });
      } else {
        enqueueSnackbar(
          'Offer uploaded successfully. Please note that the offer will be visible to users only after iswaasth approves it.',
          {
            variant: 'success',
          }
        );
        formik.resetForm();
        navigate('/dashboard/offers');
      }
    },
  });

  const isSubmitDisabled = !formik.values.title || !formik.values.offerImageUrl;

  return (
    <Box onSubmit={formik.handleSubmit} sx={{ ml: 2 }} style={{ marginTop: 24, columnGap: 12 }} component="form">
      <div className="preview-image">
        {formik.values.offerImageUrl && <img src={formik.values.offerImageUrl} alt="" />}
      </div>
      {formik.values.offerImageUrl && (
        <div style={{ paddingTop: '12px' }}>
          <Button
            onClick={async (e) => {
              e.preventDefault();
              await deleteMediaFromStorage(formik.values.offerImageUrl);
              formik.setFieldValue('offerImageUrl', '');
              enqueueSnackbar('Offer image removed successfully', {
                variant: 'success',
              });
            }}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </div>
      )}
      {selectedOfferImage && <LinearProgress />}
      <Button sx={{ my: 4 }} fullWidth variant="contained" component="label">
        Upload Offer Image
        <input
          onChange={async (e) => {
            if (formik.values.offerImageUrl) {
              enqueueSnackbar('Please delete the current offer image and then select a new one.', {
                variant: 'error',
              });
            } else {
              setSelectedOfferImage(e.target.files[0]);
              const url = await uploadMediaToStorage(e.target.files[0], 'offer_');
              formik.setFieldValue('offerImageUrl', url);
              setSelectedOfferImage();
            }
          }}
          type="file"
          hidden
        />
      </Button>
      <TextField
        onChange={formik.handleChange}
        value={formik.values.name}
        sx={{ mb: 4 }}
        fullWidth
        type="text"
        label="Title"
        name="title"
        // error={formik.touched.name && Boolean(formik.errors.name)}
        // helperText={formik.touched.name && formik.errors.name}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack flexDirection={'row'} direction="row" columnGap={4}>
          <DateTimePicker
            showTodayButton
            onChange={(date) => {
              formik.setFieldValue('startTime', date.toISOString());
            }}
            value={formik.values.startTime}
            renderInput={(props) => <TextField {...props} />}
            label="Start Time"
          />
          <DateTimePicker
            onChange={(date) => {
              formik.setFieldValue('endTime', date.toISOString());
            }}
            value={formik.values.endTime}
            renderInput={(props) => <TextField {...props} />}
            label="End Time"
          />
        </Stack>
      </LocalizationProvider>
      <LoadingButton sx={{ mt: 4 }} loading={submitting} fullWidth size="large" type="submit" variant="contained">
        Submit
      </LoadingButton>
    </Box>
  );
}
