import { styled } from '@mui/material/styles';
import { Container, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import Page from '../components/Page';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { auth } from 'src/firebase';
import { useNavigate } from 'react-router-dom';
import { changePassword, uploadCentre } from 'src/helpers/helpers';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login', { replace: true });
    }
  }, []);

  const updatePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await auth.currentUser.updatePassword(password);
      await uploadCentre({ changedPassword: true, centreId: auth.currentUser.uid }, true);
      navigate('/', { replace: true });
      enqueueSnackbar('Password updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      setIsLoading(false);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  return (
    <Page title="Change Password">
      <RootStyle>
        <Container maxWidth="sm">
          <ContentStyle>
            <Typography variant="h4" gutterBottom>
              Change your password
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              You can change the temporary password here which was provided by iswaasth. You can also change it later
              from the dashboard.
            </Typography>
            {/* <AuthSocial /> */}
            {/* <LoginForm /> */}
            {/* {error && <Alert severity="error">{error}</Alert>} */}
            <TextField
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              // helperText={errors.password && 'Password is required'}
              //  error={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <LoadingButton
              disabled={!password}
              onClick={updatePassword}
              fullWidth
              size="large"
              type="button"
              variant="contained"
              loading={isLoading}
            >
              Submit
            </LoadingButton>
            <br />
            <LoadingButton
              onClick={() => navigate('/', { replace: true })}
              fullWidth
              size="large"
              type="button"
              variant="text"
              //  loading={loading}
            >
              Skip
            </LoadingButton>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
