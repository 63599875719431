import { LoadingButton } from '@mui/lab';
import { TextField, Button } from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCentres, uploadDoctor } from '../helpers/helpers';
import { doctorValidationSchema } from '../utils/validation';
import CustomSnackbar from './CustomSnackbar';
import Loader from './Loader';
import { RegisterForm } from 'src/sections/auth/register';

export default function AddDoctorForm() {
  const [selectedFile, setselectedFile] = useState();
  const [loading, setLoading] = useState(true);
  const [centres, setCentres] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCentres, setSelectedCentres] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  async function fetchCentres() {
    const response = await getAllCentres();
    setLoading(false);
    console.log(response);
    if (response.error) {
      enqueueSnackbar('Failed to fetch hospitals/labs', {
        variant: 'error',
      });
      return;
    }
    setCentres(response.centres);
  }

  useEffect(() => {
    fetchCentres();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      qualifications: '',
      speciality: '',
    },
    validationSchema: doctorValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(selectedCentres);
      console.log('handle submit');
      console.log(values);
      values.centres = selectedCentres;
      values.profileImage = selectedFile;
      const response = await uploadDoctor(values);
      setSubmitting(false);
      console.log('Response ==>', response);
      if (response.error || response == 'error') {
        enqueueSnackbar('Failed to create entity on the server. Please check the values and try again.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(`${formik.values.name}'s Profile created succesfully!`, { variant: 'success' });
        formik.resetForm();
        setselectedFile();
        setSelectedCentres([]);
      }
    },
  });

  const { isSubmitting } = formik;

  return (
    <>
      {loading ? (
        <center>
          <Loader />
        </center>
      ) : (
        <Box onSubmit={formik.handleSubmit} component="form">
          <br />
          <div className="preview-image">{selectedFile && <img src={URL.createObjectURL(selectedFile)} alt="" />}</div>
          <br />

          <Button fullWidth variant="contained" component="label">
            Upload Profile Image
            <input
              onChange={(e) => {
                if (e.target.files[0]) {
                  setselectedFile(e.target.files[0]);
                }
              }}
              type="file"
              hidden
            />
          </Button>
          <br />
          <br />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.name}
            fullWidth
            autoComplete="username"
            type="text"
            label="Name"
            name="name"
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <br />
          <br />

          <TextField
            onChange={formik.handleChange}
            value={formik.values.qualifications}
            fullWidth
            autoComplete="username"
            type="text"
            label="Qualifications"
            name="qualifications"
            error={formik.touched.qualifications && Boolean(formik.errors.qualifications)}
            helperText={formik.touched.qualifications && formik.errors.qualifications}
          />
          <br />
          <br />
          <TextField
            onChange={formik.handleChange}
            value={formik.values.speciality}
            fullWidth
            autoComplete="username"
            type="text"
            label="Speciality"
            name="speciality"
            error={formik.touched.speciality && Boolean(formik.errors.speciality)}
            helperText={formik.touched.speciality && formik.errors.speciality}
          />
          <br />
          <br />
          <Autocomplete
            name="hospitals"
            error={formik.touched.hospitals && Boolean(formik.errors.hospitals)}
            helperText={formik.touched.hospitals && formik.errors.hospitals}
            multiple
            id="tags-outlined"
            onChange={(e, values) => {
              console.log(values);
              let _centres = selectedCentres;
              for (let i = 0; i < values.length; i++) {
                const element = values[i];
                _centres.push(element.centreId);
              }

              setSelectedCentres([..._centres]);
              console.log(selectedCentres);
            }}
            options={centres}
            getOptionLabel={(option) => option.name}
            selectOnFocus
            renderInput={(params) => (
              <TextField {...params} label="Select Hospitals/Labs" placeholder="Hospitals/Labs" />
            )}
          />
          <br />
          <br />
          {/* <Autocomplete
            multiple
            id="tags-outlined"
            options={centres}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="filterSelectedOptions" placeholder="Hospitals/Labs" />
            )}
          /> */}
          <LoadingButton
            //  onClick={handleSubmit}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Submit
          </LoadingButton>
        </Box>
      )}
    </>
  );
}
