import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
//
export const AuthContext = React.createContext();
export const Reloader = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [reload, setReload] = useState(false);

  const toggleReload = () => {
    setReload((prevReload) => !prevReload);
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      console.log('In context', user);
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <center style={{ paddingTop: '14rem' }}>
        <div className="container">
          <img height="200px" width="200px" src={`${process.env.PUBLIC_URL}/static/logo.png`} alt="" />
          <p id="loading">Loading...</p>
        </div>
      </center>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        reload,
      }}
    >
      <Reloader.Provider value={toggleReload}>{children}</Reloader.Provider>
    </AuthContext.Provider>
  );
};
